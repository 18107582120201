<template>
  <div class="home-page">
    <div class="home-search">
      <!-- <el-input
          placeholder="请输入内容"
          prefix-icon="el-icon-search"
          v-model="search">
        </el-input>       -->
    </div>
    <div class="homemenu">
      <el-row :gutter="15">
        <el-col :span="12">
          <div class="menuitem menu-bg4"><router-link to="Industry">行业领域</router-link></div>
        </el-col>
        <el-col :span="12">
          <div class="menuitem menu-bg1">
            <a href="http://victaulic.simplybrand.com/TimelineProject.html" target="_blank">百年历程</a>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="menuitem menu-bg2"><router-link to="Product">产品速递</router-link></div>
        </el-col>
        <el-col :span="12">
          <div class="menuitem menu-bg3"><router-link to="Case">成功案例</router-link></div>
        </el-col>
        <el-col :span="12">
          <div class="menuitem menu-bg5">
            <a href="https://lite.yangbentong.com/shares/cb7350f5-79c5-487e-9b33-3656614c0282" target="_blank">资料下载</a>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="menuitem menu-bg6"><router-link to="Video">视频专区</router-link></div>
        </el-col>
        <el-col :span="12">
          <div class="menuitem menu-bg8">
            <a href="http://victaulic.simplybrand.com/YunResponsePage.html" target="_blank">云上展厅</a>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="menuitem menu-bg7"><router-link to="Contact">联系我们</router-link></div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      search: ''
    }
  }
}
</script>