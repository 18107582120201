import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Case from '../views/Case.vue'
import Industry from '../views/Industry.vue'
import IndustryDetail from '../views/IndustryDetail.vue'
import Product from '../views/Product.vue'
import ProductCategory from '../views/ProductCategory.vue'
import ProductDetail from '../views/ProductDetail.vue'
import Video from '../views/Video.vue'
import VideoCategory from '../views/VideoCategory.vue'
import Download from '../views/Download.vue'
import ResponsePage from '../views/ResponsePage.vue'
import VideoDetail from '../views/VideoDetail.vue'
import Contact from '../views/Contact.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Victaulic 唯特利',
    }
  },
  {
    path: '/Case',
    name: 'Case',
    component: Case,
    meta: {
      title: '成功案例',
    }
  },
  {
    path: '/Industry',
    name: 'Industry',
    component: Industry,
    meta: {
      title: '行业领域',
    }
  },
  {
    path: '/Industry/:Iid',
    name: 'IndustryDetail',
    component: IndustryDetail,
    meta: {
      title: '行业领域',
    }
  },
  {
    path: '/Product',
    name: 'Product',
    component: Product,
    meta: {
      title: '产品速递',
    }
  },
  {
    path: '/Product/:Sid',
    name: 'ProductCategory',
    component: ProductCategory,
    meta: {
      title: '产品速递',
    }
  },
  {
    path: '/Product/:Sid/:Pid',
    name: 'ProductDetail',
    component: ProductDetail,
    meta: {
      title: '产品速递',
    }
  },
  {
    path: '/Video',
    name: 'Video',
    component: Video,
    meta: {
      title: '视频专区',
    }
  },
  {
    path: '/Video/:Cid',
    name: 'VideoCategory',
    component: VideoCategory,
    meta: {
      title: '视频专区',
    }
  },
  {
    path: '/Video/:Cid/:Vid',
    name: 'VideoDetail',
    component: VideoDetail,
    meta: {
      title: '视频专区',
    }
  },
  {
    path: '/Download',
    name: 'Download',
    component: Download,
    meta: {
      title: '资料下载',
    }
  },
  {
    path: '/ResponsePage',
    name: 'ResponsePage',
    component: ResponsePage,
    meta: {
      title: '感谢您关注Victaulic®（唯特利™）产品',
    }
  },
  {
    path: '/Contact',
    name: 'Contact',
    component: Contact,
    meta: {
      title: '联系我们',
    }
  },
]

const router = new VueRouter({
  routes
})

export default router
