<template>
  <div class="case-page container">
    <Topbar />
    <Cases />
    <Menu />
  </div>
</template>

<script>
import Topbar from '@/components/Topbar.vue'
import Cases from '@/components/Cases.vue'
import Menu from '@/components/Menus.vue'
export default {
  components: {
    Topbar,
    Cases,
    Menu
  }
}
</script>