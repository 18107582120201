<template>
  <div class="products">
    <div class="search-content">
      <el-input v-model="search" type="search" placeholder="搜索" @keyup.enter.native="onSearch()">
        <el-button slot="append" icon="el-icon-search" @click="onSearch()"></el-button>
      </el-input>
    </div>
    <div class="products-list" v-if="searchtype == false">
      <div class="product-item" v-for="item in products" :key="item.Sid">
        <router-link :to="{name: 'ProductCategory', params: {Sid: item.Sid}}">
          <img v-if="item.Sid!=15 && item.Sid!=16" :src="item.DataCoverFilePath" />
          <img v-if="item.Sid == 15" src="@/assets/img/636689095578094378.png" />
          <img v-if="item.Sid == 16" src="@/assets/img/636689104391953859.png" />
          <span class="leftboder"></span>
          <h2>{{ item.SeriesName }}</h2>
        </router-link>
      </div>
    </div>
    <div class="products-category" v-if="searchtype == true">
      <ul v-if="categorylist.length > 0">
        <li v-for="item in categorylist" :key="item.Pid">
          <router-link :to="{name: 'ProductDetail', params: {Sid: item.CatagoryType ,Pid: item.Pid}}">
            <img :src="item.IconFilePath" />
            <h2>{{ item.Title }}</h2>
            <h3>{{ item.Style }}</h3>
          </router-link>
        </li>
      </ul>
      <el-empty v-if="showsearch">
        <el-button type="primary" @click="getProductSeries()">重新搜索</el-button>
      </el-empty>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      search: this.$route.query.q == undefined ? '' : this.$route.query.q,
      cata: '',
      products: [],
      categorylist: [],
      searchtype: false,
      showsearch: false
    }
  },

  mounted(){
    let that = this
    if(that.$route.query.q != undefined){
      that.onSearch()
    }else{
      that.getProductSeries()
    }
  },

  methods: {
    /**
     * Get Lists
     */
    getProductSeries: function(){
      let that = this
      let _query = new FormData()
      _query.append(
        'json', '{"pagesize":100,"pageindex":1}'
      )
      let url = 'http://victaulic.simplybrand.com/ajaxdata/Handler.ashx/product/newProductSeriesType'
      that.$axios({
        method: 'POST',
        url: url,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: _query
      }).then(function (response) {
        that.products = response.data.value
        that.search = ''
        that.searchtype = false
      }).catch(function (error) {
        console.log(error)
      })
    },

    /**
     * 搜索
     */
    onSearch: function(){
      let that = this
      that.showsearch = false
      let _query = new FormData()
      _query.append(
        'json', '{"pagesize":100,"pageindex":1,"sid":"","SearchKeWord":"' + that.search + '","CategoryType":""}'
      )
      let url = 'http://victaulic.simplybrand.com/ajaxdata/Handler.ashx/product/newProductList'
      that.$axios({
        method: 'POST',
        url: url,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: _query
      }).then(function (response) {
        console.log(response)
        that.categorylist = response.data.value
        if(response.data.value.length == 0){
          that.showsearch = true
        }
        that.searchtype = true
      }).catch(function (error) {
        console.log(error)
      })
    },

  }
}
</script>