<template>
  <div class="video container">
    <Topbar />
    <VideosDetail />
    <Menu />
  </div>
</template>

<script>
import Topbar from '@/components/Topbar.vue'
import VideosDetail from '@/components/VideosDetail.vue'
import Menu from '@/components/Menus.vue'
export default {
  components: {
    Topbar,
    VideosDetail,
    Menu
  }
}
</script>