<template>
  <div class="home container">
    <Topbar />
    <Home />
  </div>
</template>

<script>
import Topbar from '@/components/Topbar.vue'
import Home from '@/components/Home.vue'
export default {
  components: {
    Topbar,
    Home
  }
}
</script>
