<template>
  <div class="topbar">
    <img class="logo" src="@/assets/img/logo.png" />
    <button v-if="ishome" class="menu" type="button" @click="jumphome()"></button>
  </div>
</template>

<script>
export default {
  data(){
    return{
      ishome: this.$route.name == 'Home' ? false : true
    }
  },
  methods: {
    jumphome: function(){
      this.$router.back()
    }
  }
}
</script>