<template>
  <div class="videos">
    <div class="search-content">
      <el-input v-model="search" type="search" placeholder="搜索" @keyup.enter.native="getCategory()">
        <el-button slot="append" icon="el-icon-search" @click="getCategory()"></el-button>
      </el-input>
    </div>
    <div class="videos-category">
      <ul>
        <li v-for="item in lists" :key="item.VID">
          <router-link :to="{name: 'VideoDetail', params: {Cid: item.videoCid, Vid: item.VID}}">
            <img :src="item.videoHeadimg" />
            <h2>{{ item.videoTitle }}</h2>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      sid: this.$route.params.Cid,
      search: this.$route.query.q == undefined ? '' : this.$route.query.q,
      cat: '',
      lists: []
    }
  },

  mounted(){
    this.getCategory()
  },

  methods: {
    /**
     * Get Category
     */
    getCategory: function(){
      let that = this
      let _query = new FormData()
      _query.append(
        'json', '{"pagesize":100,"pageindex":1,"sid":"' + that.sid + '","SearchKeWord":"' + that.search + '","CategoryType":"' + that.cat + '"}'
      )
      let url = 'http://victaulic.simplybrand.com/ajaxdata/Handler.ashx/Video/newVideoList'
      that.$axios({
        method: 'POST',
        url: url,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: _query
      }).then(function (response) {
        console.log(response)
        that.lists = response.data.value
      }).catch(function (error) {
        console.log(error)
      })
    },
  }
}
</script>