<template>
  <div class="response-page">
    <div class="response-page-top">
      <h2>感谢您关注Victaulic®（唯特利™）产品</h2>
      <p>联系我们(中国)</p>
      <p>Tel: 86 21 6021 9400</p>
      <p>Email: vicap@victaulic.com</p>
      <p>下载文档前，请花1分钟时间填写以下信息。</p>   
    </div>
    <div class="response-page-form">
      <el-form label-position="top" :rules="rules" ref="info" :model="info">
        <el-form-item label="您的姓名" prop="name">
          <el-input v-model="info.name"></el-input>
        </el-form-item>
        <el-form-item label="您的公司/职位？" prop="company">
          <el-input v-model="info.company"></el-input>
        </el-form-item>
        <el-form-item label="您的联系方式？" prop="contact">
          <el-input v-model="info.contact"></el-input>
        </el-form-item>
        <el-form-item label="您关注哪个行业领域？" prop="industry">
          <el-checkbox-group v-model="info.industry">
            <el-checkbox v-for="industry in industrylists" :label="industry" :key="industry">{{industry}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item>
          <el-button type="submitindustry" @click="onSubmit('info')">提交</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      info:{
        openid: this.$route.query.openid,
        name: '',
        company: '',
        contact: '',
        industry: [],
      },
      industrylists: ['商业消防','电力市场','工业厂房','海事市场','矿业市场','商业空调','石油石化','水市场'],
      rules: {
        name: [
          { required: true, message: '请输入您的姓名', trigger: 'blur' }
        ],
        company: [
          { required: true, message: '请输入您的公司/职位', trigger: 'blur' }
        ],
        contact: [
          { required: true, message: '请输入您的联系方式', trigger: 'blur' }
        ],
        industry: [
          { required: true, message: '请至少选择一个行业', trigger: 'change' }
        ],
      }
    }
  },

  mounted(){
    
  },

  methods: {

    /**
     * 提交按钮
     */
    onSubmit: function(formName){
      let that = this
      that.$refs[formName].validate((valid) => {
        if (valid) {
          if(that.info.openid != undefined && that.info.openid != '' && that.info.openid != null){
            that.onPostData()
          }else{
            alert('微信授权失败，请重新操作!')
          }
        } else {
          return false
        }
      })
    },

    /**
     * 提交表单
     */
    onPostData: function(){
      let that = this
      let _query = new FormData()
      _query.append(
        'json', '{"openid":' + that.info.openid 
        + ',"name":' + that.info.name 
        + ',"Company":' + that.info.company 
        + ',"Contact":' + that.info.contact 
        + ',"industry":' + that.info.industry + '}'
      )
      let url = 'http://victaulic.simplybrand.com/ajaxdata/Handler.ashx/dluserinfo/add'
      that.$axios({
        method: 'POST',
        url: url,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: _query
      }).then(function (response) {
        console.log(response)
        let _openid = response.data.value.split('=')[1]
        that.$router.push({name: 'Download', query: {openid: _openid}})
      }).catch(function (error) {
        console.log(error)
      })
    },
  }
}
</script>