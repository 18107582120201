<template>
  <div class="industrys">
    <div class="search-content">
      <el-input v-model="search" type="search" placeholder="搜索" @keyup.enter.native="getIndustry()">
        <el-button slot="append" icon="el-icon-search" @click="getIndustry()"></el-button>
      </el-input>
    </div>
    <div class="industry-list">
      <ul>
        <li v-for="item in industry" :key="item.Iid" :style="'background-image: url(' + item.DocumentIconPath + ');'">
          <router-link :to="{name: 'IndustryDetail', params: {Iid: item.Iid}}">
            {{ item.Title }}<i class="el-icon-arrow-right"></i>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      search: this.$route.query.q == undefined ? '' : this.$route.query.q,
      industry: []
    }
  },

  mounted(){
    this.getIndustry()
  },

  methods: {
    /**
     * Get Lists
     */
    getIndustry: function(){
      let that = this
      let _query = new FormData()
      _query.append(
        'json', '{"pagesize":100,"pageindex":1,"SearchKeWord":' + that.search + '}'
      )
      let url = 'http://victaulic.simplybrand.com/ajaxdata/Handler.ashx/Industry/getIndustrykeyList'
      that.$axios({
        method: 'POST',
        url: url,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: _query
      }).then(function (response) {
        that.industry = response.data.value
      }).catch(function (error) {
        console.log(error)
      })
    },
  }
}
</script>