<template>
  <div class="industrys">
    <div class="industrys-detail-header">
      <img :src="icon" />
      <h1>{{ title }}</h1>
    </div>
    <div class="industrys-detail-content" v-html="content">
      <!-- <p>{{ content }}</p>
      <el-divider></el-divider> -->
    </div>
    <div style="padding-left: 20px; padding-right: 20px;">
      <el-divider></el-divider>
    </div>
    <div class="industrys-detail-lists">
      <h2><i class="el-icon-collection"></i>行业样本</h2>
      <ul>
        <li v-for="item in lists" :key="item.Did">
          <h2>{{ item.DocumentSelftName }}</h2>
          <h3>{{ item.DocumentCode }}</h3>
          <a :href="item.DocumentPath" target="_blank">下载</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      iid: this.$route.params.Iid,
      title: '',
      icon: '',
      content: '',
      lists: []
    }
  },

  mounted(){
    this.getIndustryDetail()
  },

  methods: {
    /**
     * Get Detail
     */
    getIndustryDetail: function(){
      let that = this
      let _query = new FormData()
      _query.append(
        'json', '{"pagesize":100,"pageindex":1,"iid":"'+that.iid+'"}'
      )
      let url = 'http://victaulic.simplybrand.com/ajaxdata/Handler.ashx/Industry/getindustryDetail'
      that.$axios({
        method: 'POST',
        url: url,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: _query
      }).then(function (response) {
        that.title = response.data.Title
        that.icon = response.data.DocumentHeadPath
        // let _reg = /(<([^>]+)>)/ig
        // that.content = response.data.Content.replace(_reg, "")
        that.content = response.data.Content
        let _res = []
        for(let i=0; i<response.data.documents.length; i++){
          if(response.data.documents[i].DocumentSelftName != '' && response.data.documents[i].DocumentPath != ''){
            _res.push(response.data.documents[i])
          }
        }
        that.lists = _res
      }).catch(function (error) {
        console.log(error)
      })
    },

  }
}
</script>