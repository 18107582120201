<template>
  <div class="videos videos-view">
    <div class="videos-detail">
      <iframe width="100%" :src="detail.videoSrc" frameborder="0" allowfullscreen></iframe>
      <h2>{{ detail.videoTitle }}</h2>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      vid: this.$route.params.Vid,
      detail: {}
    }
  },

  mounted(){
    this.getVideoList()
  },

  methods: {
    /**
     * Get Category
     */
    getVideoList: function(){
      let that = this
      let _query = new FormData()
      _query.append(
        'json', '{"pagesize":100,"pageindex":1,"VID":"' + that.vid + '"}'
      )
      let url = 'http://victaulic.simplybrand.com/ajaxdata/Handler.ashx/Industry/getVideoList'
      that.$axios({
        method: 'POST',
        url: url,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: _query
      }).then(function (response) {
        that.detail = response.data.value[0]
      }).catch(function (error) {
        console.log(error)
      })
    },   
  }
}
</script>