<template>
  <div class="industry container">
    <Topbar />
    <IndustrysDetail />
    <Menu />
  </div>
</template>

<script>
import Topbar from '@/components/Topbar.vue'
import IndustrysDetail from '@/components/IndustrysDetail.vue'
import Menu from '@/components/Menus.vue'
export default {
  components: {
    Topbar,
    IndustrysDetail,
    Menu
  }
}
</script>