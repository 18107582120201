<template>
  <div class="product container">
    <Topbar />
    <!-- <Banner pagetitle="产品速递" /> -->
    <Products />
    <Menu />
  </div>
</template>

<script>
import Topbar from '@/components/Topbar.vue'
// import Banner from '@/components/Banner.vue'
import Products from '@/components/Products.vue'
import Menu from '@/components/Menus.vue'
export default {
  components: {
    Topbar,
    // Banner,
    Products,
    Menu
  }
}
</script>