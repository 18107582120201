<template>
  <div class="contact-page container">
    <Topbar />
    <img class="map" src="@/assets/img/map.png" />
    <h1>北亚地区</h1>
    <div class="contact-body">
      <h2>中国</h2>
      <p>上海中山西路1602号</p>
      <p>宏汇国际广场B座808室</p>
      <p>200235</p>
      <p><a href="tel:02160219400">86 21 6021 9400</a></p>
      <p><a href="mailto:vicap@victaulic.com">vicap@victaulic.com</a></p>
      <h2 class="not-first">韩国</h2>
      <p>1F, BH Building, 18-gil 5, Bangbae-ro, Seocho-gu,</p>
      <p>Seoul, Korea, 06664</p>
      <p><a href="tel:008225217235">82 2 521 7235</a></p>
      <p><a href="mailto:vicap@victaulic.com">vicap@victaulic.com</a></p>
      <h2 class="not-first">中国台湾</h2>
      <p>台湾桃园市芦竹区南工路55号, 邮编338</p>
      <p><a href="tel:0088632223220">886 3 222 3220</a></p>
      <p><a href="mailto:vicap@victaulic.com">vicap@victaulic.com</a></p>
    </div>
    <h1>其他地区</h1>
    <div class="contact-body second-contact-body">
      <el-row :gutter="10">
        <el-col :span="12">
          <h2>北美、南美地区</h2>
          <p><a href="mailto:Pickvic@victaulic.com">Pickvic@victaulic.com</a></p>
        </el-col>
        <el-col :span="12" class="concat-right-col">
          <h2>欧洲、中东、印度和非洲</h2>
          <p><a href="mailto:VicEuro@victaulic.com">VicEuro@victaulic.com</a></p>
        </el-col>
        <el-col :span="12">
          <h2 class="not-first">加拿大</h2>
          <p><a href="mailto:VicCanada@victaulic.com">VicCanada@victaulic.com</a></p>
        </el-col>
        <el-col :span="12" class="concat-right-col">
          <h2 class="not-first">巴西</h2>
          <p><a href="mailto:VicBR@victaulic.com">VicBR@victaulic.com</a></p>
        </el-col>
        <el-col :span="12">
          <h2 class="not-first">马来西亚</h2>
          <p><a href="mailto:vicsea@victaulic.com">vicsea@victaulic.com</a></p>
        </el-col>
        <el-col :span="12" class="concat-right-col">
          <h2 class="not-first">澳大利亚、新西兰</h2>
          <p><a href="mailto:vicaust@victaulic.com">vicaust@victaulic.com</a></p>
        </el-col>
      </el-row>     
    </div>
    <Menu />
  </div>
</template>

<script>
import Topbar from '@/components/Topbar.vue'
import Menu from '@/components/Menus.vue'
export default {
  components: {
    Topbar,
    Menu
  }
}
</script>