<template>
  <div class="products">
    <div class="products-detail">
      <div class="products-detail-header">
        <img :src="iconfilepath" />
        <h2>{{ title }}</h2>
        <h3>{{ style }}</h3>
      </div>
      <div class="products-detail-desc" v-html="content">
        <!-- <p>{{ content }}</p> -->
      </div>
      <div class="products-detail-tabs">
        <el-tabs type="border-card">
          <el-tab-pane label="技术资料">
            <ul v-if="tech.length > 0">
              <li v-for="item in tech" :key="item.Did">
                <h2>{{ item.DocumentSelftName }}</h2>
                <h3>{{ item.DocumentCode }}</h3>
                <a :href="item.DocumentPath" target="_blank">下载</a>
              </li>
            </ul>
          </el-tab-pane>
          <el-tab-pane label="安装手册">
            <ul v-if="install.length > 0">
              <li v-for="item in install" :key="item.Did">
                <h2>{{ item.DocumentSelftName }}</h2>
                <h3>{{ item.DocumentCode }}</h3>
                <a :href="item.DocumentPath" target="_blank">下载</a>
              </li>
            </ul>
          </el-tab-pane>
          <el-tab-pane label="样本">
            <ul v-if="sample.length > 0">
              <li v-for="item in sample" :key="item.Did">
                <h2>{{ item.DocumentSelftName }}</h2>
                <h3>{{ item.DocumentCode }}</h3>
                <a :href="item.DocumentPath" target="_blank">下载</a>
              </li>
            </ul>
          </el-tab-pane>
        </el-tabs>        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      pid: this.$route.params.Pid,
      title: '',
      style: '',
      content: '',
      iconfilepath: '',
      tech: [],
      install: [],
      sample: []
    }
  },

  mounted(){
    this.getProductDetail()
  },

  methods: {
    /**
     * Get Detail
     */
    getProductDetail: function(){
      let that = this
      let _query = new FormData()
      _query.append(
        'json', '{"pid":' + that.pid +'}'
      )
      let url = 'http://victaulic.simplybrand.com/ajaxdata/Handler.ashx/product/getnewProductDetail'
      that.$axios({
        method: 'POST',
        url: url,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: _query
      }).then(function (response) {
        that.title = response.data.Title
        that.style = response.data.Style
        that.iconfilepath = response.data.IconFilePath
        // let _reg = /(<([^>]+)>)/ig
        // that.content = response.data.Content.replace(_reg, "")
        that.content = response.data.Content
        let _tech = []
        let _install = []
        let _sample = []
        let _doc = response.data.documents
        for(let i=0; i<_doc.length; i++){
          if(_doc[i].DocumentType == 'Tech'){
            _tech.push(_doc[i])
          }
          if(_doc[i].DocumentType == 'Install'){
            _install.push(_doc[i])
          }
          if(_doc[i].DocumentType == 'Sample'){
            _sample.push(_doc[i])
          }
        }
        that.tech = _tech
        that.install = _install
        that.sample = _sample
      }).catch(function (error) {
        console.log(error)
      })
    },

  }
}
</script>