<template>
  <div class="download container">
    <Topbar />
    <Downloads />
    <Menu />
  </div>
</template>

<script>
import Topbar from '@/components/Topbar.vue'
import Downloads from '@/components/Downloads.vue'
import Menu from '@/components/Menus.vue'
export default {
  components: {
    Topbar,
    Downloads,
    Menu
  }
}
</script>