<template>
  <div class="videos">
    <div class="search-content">
      <el-input v-model="search" type="search" placeholder="搜索" @keyup.enter.native="onSearch()">
        <el-button slot="append" icon="el-icon-search" @click="onSearch()"></el-button>
      </el-input>
    </div>
    <div class="videos-list" v-if="searchtype == false">
      <ul>
        <li v-for="item in category" :key="item.Sid">
          <router-link :to="{name: 'VideoCategory', params: {Cid: item.Sid}}">
            <img :src="item.DataCoverFilePath" />
            <h2>{{ item.SeriesName }}</h2>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="videos-category" v-if="searchtype == true">
      <ul v-if="lists.length>0">
        <li v-for="item in lists" :key="item.VID">
          <router-link :to="{name: 'VideoDetail', params: {Cid: item.videoCid, Vid: item.VID}}">
            <img :src="item.videoHeadimg" />
            <h2>{{ item.videoTitle }}</h2>
          </router-link>
        </li>
      </ul>
      <el-empty v-if="showsearch">
        <el-button type="primary" @click="getCategory()">重新搜索</el-button>
    </el-empty>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      search: this.$route.query.q == undefined ? '' : this.$route.query.q,
      cat: '',
      category: [],
      searchtype: false,
      lists: [],
      showsearch: false
    }
  },

  mounted(){
    if(this.search != undefined && this.search != '' && this.search != null){
      this.onSearch()
    }else{
      this.getCategory()
    }
  },

  methods: {
    /**
     * Get Category
     */
    getCategory: function(){
      let that = this
      that.showsearch = false
      let _query = new FormData()
      _query.append(
        'json', '{"pagesize":100,"pageindex":1}'
      )
      let url = 'http://victaulic.simplybrand.com/ajaxdata/Handler.ashx/video/newVideoSeriesType'
      that.$axios({
        method: 'POST',
        url: url,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: _query
      }).then(function (response) {
        console.log(response)
        that.category = response.data.value
        if(response.data.value.length == 0){
          that.showsearch = true
        }
      }).catch(function (error) {
        console.log(error)
      })
    },

    /**
     * Get Category
     */
    onSearch: function(){
      let that = this
      let _query = new FormData()
      _query.append(
        'json', '{"pagesize":100,"pageindex":1,"sid":"","SearchKeWord":"' + that.search + '","CategoryType":"' + that.cat + '"}'
      )
      let url = 'http://victaulic.simplybrand.com/ajaxdata/Handler.ashx/Video/newVideoList'
      that.$axios({
        method: 'POST',
        url: url,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: _query
      }).then(function (response) {
        console.log(response)
        that.lists = response.data.value
        that.searchtype = true
      }).catch(function (error) {
        console.log(error)
      })
    },

  }
}
</script>