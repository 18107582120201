<template>
  <div class="products">
    <div class="search-content">
      <el-input v-model="search" type="search" placeholder="搜索" @keyup.enter.native="getCategory()">
        <el-button slot="append" icon="el-icon-search" @click="getCategory()"></el-button>
      </el-input>
    </div>
    <div class="product-breadcrumb">
      <h2>{{ seriesname }}</h2>
    </div>
    <div class="products-category">
      <div v-if="sid == 20" class="list-pif-box">
        <a href="//victaulic.simplybrand.com/FireLock/"><img src="@/assets/img/category-header.png" /></a>
      </div>
      <ul v-if="categorylist.length > 0">
        <li v-for="item in categorylist" :key="item.Pid">
          <router-link :to="{name: 'ProductDetail', params: {Pid: item.Pid}}">
            <img :src="item.IconFilePath" />
            <h2>{{ item.Title }}</h2>
            <h3>{{ item.Style }}</h3>
          </router-link>
        </li>
      </ul>
      <el-empty v-if="showsearch">
        <el-button type="primary" @click="search=''; getCategory();">重新搜索</el-button>
      </el-empty>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      search: this.$route.query.q == undefined ? '' : this.$route.query.q,
      cata: '',
      sid: this.$route.params.Sid,
      categorylist: [],
      seriesname: '',
      showsearch: false
    }
  },

  mounted(){
    this.getCategory()
  },

  methods: {
    /**
     * Get Category
     */
    getCategory: function(){
      let that = this
      that.showsearch = false
      let _query = new FormData()
      _query.append(
        'json', '{"pagesize":100,"pageindex":1,"sid":'+that.sid+',"SearchKeWord":"' + that.search + '","CategoryType":""}'
      )
      let url = 'http://victaulic.simplybrand.com/ajaxdata/Handler.ashx/product/newProductList'
      that.$axios({
        method: 'POST',
        url: url,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: _query
      }).then(function (response) {
        console.log(response)
        that.categorylist = response.data.value
        if(response.data.value.length == 0){
          that.showsearch = true
        }
        that.seriesname = response.data.value[0].SeriesName
      }).catch(function (error) {
        console.log(error)
      })
    },
  }
}
</script>